/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'SegoeUI';
  src: url('fonts/Segoe UI.ttf');
}

.font-segoeUI {
  font-family: 'SegoeUI', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Work Sans', sans-serif;
}

.font-segoeUISemibold {
  font-family: 'Work Sans', sans-serif;
}

/* Font copied in place by React Studio. Please check that your license for this font file permits embedding on a website. */
@font-face {
  font-family: 'Work Sans', sans-serif;
}

.font-poppinsSemiBold {
  font-family: 'Work Sans', sans-serif;
}

.App { /* Base font style */
  font-family: 'Work Sans', sans-serif;
  font-size: 15.2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #fafafa;
}

.Test {
  background-color: #8ee9ff;
  color: white;
  width: 175px;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}
.Test2{
  display: none;
}

.Test3 {
  background-color: white;
  color: black;
  width: 175px;
  height: 50px;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}
.Test4{
  display: none;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: 'Work Sans', sans-serif;
  font-size: 15.2px;
}

.App .appBg {
  background-color: #fafafa;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #bee9ee;
}

.App .primaryBg {
  background-color: #bee9ee;
}

.App .highlightTextColor {
  color: #0093d5;
}

.App .highlightBg {
  background-color: #0093d5;
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #bee9ee;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #c3ebf0;
}
.mui-btn--accent {
    background-color: #0093d5;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #069fda;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: 'Work Sans', sans-serif;
}
.App .systemFontBold {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: 'Work Sans', sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: 'Work Sans', sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: 'Work Sans', sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: 'Work Sans', sans-serif;
  font-size: 19.0px;
  font-weight: 400;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #bee9ee;
  color: black;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 62px;
  min-height: 62px;
  font-family: 'Work Sans', sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: black;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
  margin-top: 13px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  margin-top: 13px;
  cursor: pointer;
}

* {
  font-family: 'Work Sans', sans-serif;
}

@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

/* This component has a fixed-size layout */
  .Component1 {
    min-height: 150px;
    width: 150px; /* This component was designed using a width of 150px */
    height: 150px;
  }
  .Component1 > .background > .containerMinHeight {
    min-height: 150px;
  }

  .Component1 > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Component1 > .layoutFlow > .flowRow > .elImage > * {
    width: 100%;
    height: 150px;
  }

  .flowRow_Component1_elImage_531938 {
  }


.Component1 .elFab button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Component1 .elFab button:focus {
  box-shadow: 0 0 5px rgba(54, 64, 79, 0.5000);
  outline: none;
}



  .Component1 > .layoutFlow > .elFab {
    position: relative;
    margin-top: 5px;
    width: 15px;
    margin-left: 5px;
  }
  .Component1 > .layoutFlow > .elFab > * {
  width: 25px;
  height: 25px;
  }



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .background > .state0_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MainPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .background > .state0_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard > * {
  width: 300px;
  height: 310px;
  }

  .flowRow_MainPageScreen_state0_elContainerCard_884287 {
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard > * {
  width: 300px;
  height: 310px;
  }

  .flowRow_MainPageScreen_state0_elContainerCard_884287 {
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard > * {
  width: 298px;
  height: 310px;
  }

  .flowRow_MainPageScreen_state0_elContainerCard_884287 {
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state0_elContainerCard > * {
  width: 298px;
  height: 310px;
  }

  .flowRow_MainPageScreen_state0_elContainerCard_884287 {
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state0_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 125px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTitleText > * {
  width: 175px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state0_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 125px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTitleText > * {
  width: 175px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state0_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 124px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTitleText > * {
  width: 174px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state0_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 124px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTitleText > * {
  width: 174px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% + -124px);
  }
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% + -124px);
  }
  .MainPageScreen > .layoutFlow > .state0_elInstructionsText > * {
  width: 248px;
  }

}

.MainPageScreen .state0_elAddPhoto button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state0_elAddPhoto button:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto > * {
  width: 175px;
  height: 50px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto > * {
  width: 175px;
  height: 50px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto > * {
  width: 174px;
  height: 50px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elAddPhoto > * {
  width: 174px;
  height: 50px;
  }

}

.MainPageScreen .state0_elTakePhoto button {
  cursor: pointer;
  border: 1.0px solid #8ee9ff;
}

.MainPageScreen .state0_elTakePhoto button:focus {
  box-shadow: 0 0 5px rgba(0, 222, 215, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto > * {
  width: 175px;
  height: 50px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto > * {
  width: 175px;
  height: 50px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto > * {
  width: 174px;
  height: 50px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state0_elTakePhoto > * {
  width: 174px;
  height: 50px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .background > .state1_elBackground35134 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MainPageScreen > .background > .state1_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .background > .state1_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .background > .state1_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

  .MainPageScreen .state1_elList638512:after {
    content: "";
    display: table;
    clear: both;
  }

  .MainPageScreen .state1_elList638512 .gridItem {
    float: left;
    position: relative;
  }

@media (min-width: 1024px) {
  .MainPageScreen .state1_elList638512 .gridItem {
    width: calc(100% / 2 - 29.8px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state1_elList638512 .cols2_0 {
    margin-right: 29.8px;
    margin-bottom: 29.8px;
    clear: left;
  }
  .MainPageScreen .state1_elList638512 .cols2_1 {
    margin-bottom: 29.8px;
  }
  .MainPageScreen .state1_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 1023px) {
  .MainPageScreen .state1_elList638512 .gridItem {
    width: calc(100% / 2 - 29.8px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state1_elList638512 .cols2_0 {
    margin-right: 29.8px;
    margin-bottom: 29.8px;
    clear: left;
  }
  .MainPageScreen .state1_elList638512 .cols2_1 {
    margin-bottom: 29.8px;
  }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 767px) {
  .MainPageScreen .state1_elList638512 .gridItem {
    width: calc(100% / 2 - 30.0px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state1_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_0 {
    margin-right: 30.0px;
    margin-bottom: 30.0px;
    clear: left;
  }
  .MainPageScreen .state1_elList638512 .cols2_1 {
    margin-bottom: 30.0px;
  }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 567px) {
  .MainPageScreen .state1_elList638512 .gridItem {
    width: calc(100% / 2 - 30.0px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state1_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state1_elList638512 .cols2_0 {
    margin-right: 30.0px;
    margin-bottom: 30.0px;
    clear: left;
  }
  .MainPageScreen .state1_elList638512 .cols2_1 {
    margin-bottom: 30.0px;
  }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after { }
  .MainPageScreen .state1_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state1_elList638512 {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state1_elList638512 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state1_elList638512 {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state1_elList638512 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state1_elList638512 {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state1_elList638512 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state1_elList638512 {
    position: relative;
    margin-top: 100px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state1_elList638512 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elTopContainer385950 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 99px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 99px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elText948541 {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + -119px);
    top: 30px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -119px);
    top: 30px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -118px);
    top: 30px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -118px);
    top: 30px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elBottomContainer628788 {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 100px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 100px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 99px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 99px;
  }
}

.MainPageScreen .state1_elButton365297 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state1_elButton365297:focus {
  box-shadow: 0 0 6px rgba(54, 64, 79, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButton365297 {
    position: fixed;
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}

.MainPageScreen .state1_elButtonCopy615817 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state1_elButtonCopy615817:focus {
  box-shadow: 0 0 6px rgba(54, 64, 79, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButtonCopy615817 {
    position: fixed;
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state1_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}

.GrayScreen {
  position: absolute;
  z-index: 2;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100.0%;
  height: 100%;
}

.Loading {
  position: absolute;
  z-index: 3;
  top: 40%;
  height: 20%;
  left: 40%;
  width: 20%
}

/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .background > .state2_elBackground35134 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MainPageScreen > .background > .state2_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .background > .state2_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .background > .state2_elBackground35134 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

  .MainPageScreen .state2_elList638512:after {
    content: "";
    display: table;
    clear: both;
  }

  .MainPageScreen .state2_elList638512 .gridItem {
    float: left;
    position: relative;
  }

@media (min-width: 1024px) {
  .MainPageScreen .state2_elList638512 .gridItem {
    width: calc(100% / 2 - 29.8px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state2_elList638512 .cols2_0 {
    margin-right: 29.8px;
    margin-bottom: 29.8px;
    clear: left;
  }
  .MainPageScreen .state2_elList638512 .cols2_1 {
    margin-bottom: 29.8px;
  }
  .MainPageScreen .state2_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 1023px) {
  .MainPageScreen .state2_elList638512 .gridItem {
    width: calc(100% / 2 - 29.8px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state2_elList638512 .cols2_0 {
    margin-right: 29.8px;
    margin-bottom: 29.8px;
    clear: left;
  }
  .MainPageScreen .state2_elList638512 .cols2_1 {
    margin-bottom: 29.8px;
  }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 767px) {
  .MainPageScreen .state2_elList638512 .gridItem {
    width: calc(100% / 2 - 30.0px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state2_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_0 {
    margin-right: 30.0px;
    margin-bottom: 30.0px;
    clear: left;
  }
  .MainPageScreen .state2_elList638512 .cols2_1 {
    margin-bottom: 30.0px;
  }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}

@media (max-width: 567px) {
  .MainPageScreen .state2_elList638512 .gridItem {
    width: calc(100% / 2 - 30.0px*(1/2));
    overflow: hidden;
  }

  .MainPageScreen .state2_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_0 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_1 { margin-right: 0; margin-bottom: 0; }
  .MainPageScreen .state2_elList638512 .cols2_0 {
    margin-right: 30.0px;
    margin-bottom: 30.0px;
    clear: left;
  }
  .MainPageScreen .state2_elList638512 .cols2_1 {
    margin-bottom: 30.0px;
  }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after { }
  .MainPageScreen .state2_elList638512 .cols2_1:after {
    content: "";
    display: table;
    clear: both;
  }

}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state2_elList638512 {
    position: relative;
    margin-top: 84px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state2_elList638512 > * {
    width: 100%;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state2_elList638512 {
    position: relative;
    margin-top: 84px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state2_elList638512 > * {
    width: 100%;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state2_elList638512 {
    position: relative;
    margin-top: 84px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state2_elList638512 > * {
    width: 100%;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state2_elList638512 {
    position: relative;
    margin-top: 84px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MainPageScreen > .layoutFlow > .state2_elList638512 > * {
    width: 100%;
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTopContainer385950 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 99px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTopContainer385950 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 99px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elText948541 {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + -119px);
    top: 30px;
    width: 238px;
    height: 26px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -119px);
    top: 30px;
    width: 238px;
    height: 26px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -118px);
    top: 30px;
    width: 236px;
    height: 26px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elText948541 {
    overflow: hidden;
    left: calc(50.0% + -118px);
    top: 30px;
    width: 236px;
    height: 26px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elBottomContainer628788 {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 100px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 100px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 99px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elBottomContainer628788 {
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 99px;
  }
}

.MainPageScreen .state2_elButton365297 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state2_elButton365297:focus {
  box-shadow: 0 0 6px rgba(54, 64, 79, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButton365297 {
    position: fixed;
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButton365297 {
    left: calc(25.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}

.MainPageScreen .state2_elButtonCopy615817 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state2_elButtonCopy615817:focus {
  box-shadow: 0 0 6px rgba(54, 64, 79, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButtonCopy615817 {
    position: fixed;
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 100px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elButtonCopy615817 {
    left: calc(75.0% + -50px);
    bottom: 25px;
    width: 99px;
    height: 60px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elCard {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elCard {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elCard {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elContainerCardCopy {
    position: absolute;
    left: calc(50.0% + -150px);
    top: 100px;
    width: 300px;
    height: 290px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elContainerCardCopy {
    left: calc(50.0% + -150px);
    top: 100px;
    width: 300px;
    height: 290px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elContainerCardCopy {
    left: calc(50.0% + -149px);
    top: 99px;
    width: 298px;
    height: 308px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elContainerCardCopy {
    left: calc(50.0% + -149px);
    top: 99px;
    width: 298px;
    height: 308px;
  }
}

.MainPageScreen .state2_elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state2_elFab:focus {
  box-shadow: 0 0 6px rgba(0, 149, 214, 0.2500);
  outline: none;
}

/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elFab {
    line-height: 40px;
    position: absolute;
    left: calc(50.0% + -20px);
    top: 125px;
    width: 40px;
    height: 40px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elFab {
    line-height: 40px;
    left: calc(50.0% + -20px);
    top: 125px;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elFab {
    line-height: 40px;
    left: calc(50.0% + -20px);
    top: 124px;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elFab {
    line-height: 40px;
    left: calc(50.0% + -20px);
    top: 124px;
    width: 40px;
    height: 40px;
  }
}

.MainPageScreen .state2_elAddPhotoCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state2_elAddPhotoCopy:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elAddPhotoCopy {
    position: absolute;
    left: calc(50.0% + -88px);
    top: 310px;
    width: 175px;
    height: 50px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elAddPhotoCopy {
    left: calc(50.0% + -88px);
    top: 310px;
    width: 175px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elAddPhotoCopy {
    left: calc(50.0% + -87px);
    top: 308px;
    width: 174px;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elAddPhotoCopy {
    left: calc(50.0% + -87px);
    top: 308px;
    width: 174px;
    height: 50px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elInstructionsTextCopy {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + -112px);
    top: 210px;
    width: 225px;
    height: 90px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elInstructionsTextCopy {
    overflow: hidden;
    left: calc(50.0% + -112px);
    top: 210px;
    width: 225px;
    height: 90px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elInstructionsTextCopy {
    overflow: hidden;
    left: calc(50.0% + -112px);
    top: 209px;
    width: 224px;
    height: 90px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elInstructionsTextCopy {
    overflow: hidden;
    left: calc(50.0% + -112px);
    top: 209px;
    width: 224px;
    height: 90px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTitleTextCopy {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + -62px);
    top: 180px;
    width: 125px;
    height: 28px;
  }
@media (min-width: 568px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTitleTextCopy {
    overflow: hidden;
    left: calc(50.0% + -62px);
    top: 180px;
    width: 125px;
    height: 28px;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTitleTextCopy {
    overflow: hidden;
    left: calc(50.0% + -62px);
    top: 179px;
    width: 124px;
    height: 28px;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .screenFgContainer > .foreground > .state2_elTitleTextCopy {
    overflow: hidden;
    left: calc(50.0% + -62px);
    top: 179px;
    width: 124px;
    height: 28px;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .background > .state3_elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MainPageScreen > .background > .state3_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MainPageScreen > .background > .state3_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MainPageScreen > .background > .state3_elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard > * {
  width: 300px;
  height: 225px;
  }

  .flowRow_MainPageScreen_state3_elContainerCard_772422 {
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard > * {
  width: 300px;
  height: 225px;
  }

  .flowRow_MainPageScreen_state3_elContainerCard_772422 {
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard > * {
  width: 298px;
  height: 224px;
  }

  .flowRow_MainPageScreen_state3_elContainerCard_772422 {
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard {
    position: absolute;
    margin-top: 100px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .MainPageScreen > .layoutFlow > .flowRow > .state3_elContainerCard > * {
  width: 298px;
  height: 224px;
  }

  .flowRow_MainPageScreen_state3_elContainerCard_772422 {
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state3_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 125px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state3_elTitleText > * {
  width: 175px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state3_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 125px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state3_elTitleText > * {
  width: 175px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state3_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 124px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state3_elTitleText > * {
  width: 174px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state3_elTitleText {
    overflow: hidden;
    position: relative;
    margin-top: 124px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state3_elTitleText > * {
  width: 174px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText > * {
  width: 250px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText > * {
  width: 250px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% + -124px);
  }
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText > * {
  width: 248px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    width: 248px;
    margin-left: calc(50.0% + -124px);
  }
  .MainPageScreen > .layoutFlow > .state3_elInstructionsText > * {
  width: 248px;
  }

}

.MainPageScreen .state3_elAddPhoto button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.MainPageScreen .state3_elAddPhoto button:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto > * {
  width: 175px;
  height: 50px;
  }

@media (min-width: 568px) {
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 175px;
    margin-left: calc(50.0% + -88px);
  }
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto > * {
  width: 175px;
  height: 50px;
  }

}
@media (min-width: 768px) {
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto > * {
  width: 174px;
  height: 50px;
  }

}
@media (min-width: 1024px) {
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto {
    position: relative;
    margin-top: 25px;
    width: 174px;
    margin-left: calc(50.0% + -87px);
  }
  .MainPageScreen > .layoutFlow > .state3_elAddPhoto > * {
  width: 174px;
  height: 50px;
  }

}

.gridItem {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-right: 0;
}
